<template>
  <div class="container mt-4">

    <div class="row g-1">
      <!-- Render view from layout conf -->
      <div class="col-12">
        <BuildingOverview></BuildingOverview>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body p-2">
            <StartStopScheduler title="Graafik" station="null" extra="null"></StartStopScheduler>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body p-2">
            <StartScheduler title="Graafik 2" station="null" extra="null"></StartScheduler>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import StartStopScheduler from '../components/UI/StartStopScheduler'
import StartScheduler from '../components/UI/StartScheduler'
import BuildingOverview from '../components/UI/BuildingOverview'
export default {
  components: { StartStopScheduler, StartScheduler, BuildingOverview }
}
</script>
