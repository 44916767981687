<template>
  <div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th>{{ title }}</th>
        <th>Aktiivne</th>
        <th>Sisse</th>
        <th>Välja</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(day, index) in days" :key="index">
        <td>
          {{ day }}
        </td>
        <td>
          <div class="form-check">
            <input class="form-check-input mx-0" type="checkbox" v-model="schedule[index].active" :id="'day-' + index">
            <label class="form-check-label" :for="'day-' + index">
            </label>
          </div>
        </td>
        <td>
          <VueTimepicker :disabled="!schedule[index].active" v-model="schedule[index].start" close-on-complete hide-clear-button></VueTimepicker>
        </td>
        <td>
          <VueTimepicker :disabled="!schedule[index].active" v-model="schedule[index].stop" close-on-complete hide-clear-button></VueTimepicker>
        </td>
      </tr>
      </tbody>
    </table>
    <pre>
      {{ schedule }}
    </pre>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
export default {
  props: ['title', 'extra'],
  components: { VueTimepicker },
  data () {
    return {
      days: ['Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev', 'Pühapäev'],
      schedule: [
        { day: 0, active: false, start: '00:00', stop: '23:59' },
        { day: 1, active: false, start: '00:00', stop: '23:59' },
        { day: 2, active: false, start: '00:00', stop: '23:59' },
        { day: 3, active: false, start: '00:00', stop: '23:59' },
        { day: 4, active: false, start: '00:00', stop: '23:59' },
        { day: 5, active: false, start: '00:00', stop: '23:59' },
        { day: 6, active: false, start: '00:00', stop: '23:59' }
      ]
    }
  }
}
</script>
