<template>
  <div>
    123
  </div>
</template>

<script>
export default {
  props: ['title', 'extra'],
  data () {
    return {
      areas: []
    }
  }
}
</script>
